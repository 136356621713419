<template>
    <div id="app" v-if="is_wxixin">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <div v-if="$store.state.tabbar" class="tabbar_height"></div>
        <van-tabbar v-model="active" v-if="$store.state.tabbar" class="tabbar_h5">
            <van-tabbar-item replace v-for="item in tabbarArray" :key="item.index" :to="item.to">
                <span class="text">{{item.text}}</span>
                <template #icon="props">
                    <img :src="props.active ? item.activeImg : item.inactiveImg" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <div v-else id="app" class="wx_content">
        <van-icon name="warning" class="wx_icon" />
        <div class="wx_text">请在微信客户端打开</div>
    </div>
</template>

<script>
// import config from "@/utils/config.js";
export default {
    data() {
        return {
            active: 0,
            is_wxixin: true, // 是否是微信浏览器
            tabbarArray: [{
                to: '/recruitmentList',
                text: '找工作',
                activeImg: require('../src/assets/img/work_check.png'),
                inactiveImg: require('../src/assets/img/work_no.png'),
            }, {
                to: '/home',
                text: '我的',
                activeImg: require('../src/assets/img/my_in_check.png'),
                inactiveImg: require('../src/assets/img/my_in_no.png'),
            }],
            // info: {},
        };
    },
    name: 'App',
    watch: {
        '$store.state.active': function () {
            this.active = this.$store.state.active
        }
    },
    created() {
        (function () {
            function handleFontSize() {
                // 设置网页字体为默认大小
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': '0' });  // eslint-disable-line
                // 重写设置网页字体大小的事件
                WeixinJSBridge.on('menu:setfont', function () {  // eslint-disable-line
                    WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': '0' });  // eslint-disable-line
                });
            }
            if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {  // eslint-disable-line
                handleFontSize();
            } else {
                if (document.addEventListener) {
                    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
                    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
                }
            }
        })();
        this.is_wxixin = this.isWeiXin()
        this.getMemberInfo()
    },
    mounted() {
        window.addEventListener('offline', () => {
            // 网络由正常常到异常时触发
            sessionStorage.locationUrl = window.location.href;
            this.$router.replace('/networkError')
        });
        window.addEventListener('online', () => {
            window.location.href = sessionStorage.locationUrl
        });
    },
    methods: {
        // 判断是否能用微信
        isWeiXin() {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        getMemberInfo(value) {
            /** 
            0: '海报码', src=0
            1: '报名码', 
            2: '手动添加',
            3: '推荐码',
            4: '入职码',
            5: '公众号'
            */
           
            var data = {}
            if (this.getQuery('member_id') || this.getQuery('m_id')) {
                if (this.getQuery('src') === 0) { // 海报码，详图，简图
                    sessionStorage.setItem('source', 0)
                } else if (this.getQuery('introducer_id') > 0 || this.getQuery('int_id') > 0) { // 推荐码，推荐海报
                    sessionStorage.setItem('introducer_id', this.getQuery('introducer_id') || this.getQuery('int_id') || -1)
                    sessionStorage.setItem('source', 3)
                } else { // 报名码，找工作列表页面的报名码
                    sessionStorage.setItem('source', 1)
                }
                if (this.getQuery('jobseeker_id') || this.getQuery('jobseek_id')) { // 从H5分享出来的
                    localStorage.setItem('jobseeker_id', this.getQuery('jobseeker_id') || this.getQuery('jobseek_id') || -1)
                }
                if (this.getQuery('member_id') || this.getQuery('m_id')) {
                    data.member_id = this.getQuery('member_id') || this.getQuery('m_id')
                    localStorage.setItem('member_id', data.member_id)
                }
            } else if (this.getQuery('team_id')) { // 只有team_id就是从公众号进来的
                data.team_id = this.getQuery('team_id')
                localStorage.removeItem('member_id')
                localStorage.setItem('team_id', data.team_id)
                sessionStorage.setItem('source', 5)
                sessionStorage.setItem('is_getAllList', 1)
            } else {
                if (localStorage.getItem('member_id')) {
                    data.member_id = localStorage.getItem('member_id')
                } else {
                    data.team_id = localStorage.getItem('team_id')
                }
                sessionStorage.setItem('source', 5)
            }
            if (this.getQuery('source')) {

                sessionStorage.setItem('source', this.getQuery('source') || sessionStorage.getItem('source'))
            }
            
            var url = window.location.href.split('#')[1]
            var path = url.split('?')[0]
            if (localStorage.getItem('token') && !this.getQuery('rid') && path != '/recruitmentList') { // 没有token跟有rid的时候不请求该接口
                this.Request('client/user/member', 'get', data, true, false).then(res => {
                    if (res.status == 0) {
                        localStorage.setItem("team_id", res.data.team_id);
                        localStorage.setItem("member_id", res.data.member_id);
                        this.$store.state.memberInfo = res.data;
                    } else {
                        if (!value) {
                            this.getMemberInfo(true)
                        }

                    }
                })
            }
        },
    }

}
</script>

<style>

* {
    padding: 0;
    margin: 0;
    font-family: "微软雅黑";
    font-size: 28px;
}
.wx_icon {
    font-size: 120px;
    margin-bottom: 60px;
    margin-top: 300px;
    color: #0abbb9;
}
.wx_text {
    font-size: 40px;
}
body {
    background-color: #f8f8f8;
}
.wx_content {
    text-align: center;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #333;
    min-height: 100vh;
    box-sizing: border-box;
    /* margin-top: 60px; */
}
.contact_fixed {
    text-align: center;
    position: fixed;
    right: 50px;
    bottom: 200px;
    opacity: 0.9;
}
.tabbar_h5 {
    padding-bottom: 0 !important;
    height: 100px;
}
.tabbar_h5 .text{font-size: 22px !important;}
.tabbar_h5 img {
    width: 48px;
    height: 48px;
}
.contact_fixed div {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.contact_fixed div i {
    font-size: 46px;
    color: #fff;
    line-height: 70px;
    height: 70px;
    display: flow-root;
}
.van-nav-bar {
    background: #0abbb9;
    position: fixed;
    width: 100%;
    z-index: 3;
}
.van-nav-bar .van-icon,
.van-nav-bar .van-nav-bar__text,
.van-nav-bar .van-nav-bar__title,
.van-nav-bar .van-nav-bar__text {
    color: #fff;
}
.van-hairline--bottom::after {
    border-bottom-width: 0;
}
.tabbar_height {
    height: 1.33333rem;
}
.van-tabbar-item__icon{margin-bottom: 0;}
.van-tabbar-item--active {
    color: #0abbb9;
}
</style>
