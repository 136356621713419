import Vue from "vue";
import Vuex from "vuex";
import wx from "weixin-js-sdk";
import config from "@/utils/config.js";
import Request from '@/utils/request.js'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        tabbar: false,
        active: 0,
        //存放的键值对就是所要管理的状态
        idcardList: {},
        bankList: {},
        applyList: {},
        memberInfo: {}, // 业务员基本信息，求职者联系业务员用
        idcardURL: [],
        iszidingy: -1, //判断是自定义标签还是系统标签
        screen: {}, // 筛选的列表  不会改变的
        screenValue: {}, // 筛选的值   会改变的
        refreshList: 0, // 刷新招工信息管理列表
        latitude: "",
        longitude: "",
    },
    mutations: {
        showTabbar(state) {
            return (state.tabbar = true);
        },
        hideTabbar(state) {
            return (state.tabbar = false);
        },
        // 做分享用，需要使用wx的jssdk
        getWxSDK(state) {
            var url = window.location.href.split("#")[0]; //动态获取链接
            var data = {
                url,
                mtype: "worker_agent_team_mp",
            };
            Request(
                "client/api/wechat/jssdk/jsapi_ticket",
                "get",
                data,
                false
            ).then((res) => {
                res = res.data;
                wx.config({
                    debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
                    appId: res.appId, // 微信appid
                    timestamp: res.timestamp, // 时间戳
                    nonceStr: res.nonceStr, // 随机字符串
                    signature: res.signature, // 签名
                    jsApiList: [
                        'checkJsApi',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'onMenuShareQZone',
                        'closeWindow',
                    ]
                });
                wx.checkJsApi({
                    jsApiList: [
                        'checkJsApi',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'onMenuShareQZone',
                        'closeWindow',
                    ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function () {
                        //在这里提示点击右上角分享
                    }
                });
                var ua = window.navigator.userAgent.toLowerCase();
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    wx.ready(() => {
                        var link
                        if (sessionStorage.getItem('introducer_id')) {
                            link = process.env.VUE_APP_BASE_URL + `recruitmentList?member_id=${state.memberInfo.member_id}&introducer_id=`+sessionStorage.getItem('introducer_id')
                        }else if (state.memberInfo.jobseeker_id) {
                            link = process.env.VUE_APP_BASE_URL + `recruitmentList?member_id=${state.memberInfo.member_id}&jobseeker_id=${state.memberInfo.jobseeker_id}&source=`+sessionStorage.getItem('source')
                        } else {
                            link = process.env.VUE_APP_BASE_URL + `recruitmentList?team_id=${state.memberInfo.team_id}&source=`+sessionStorage.getItem('source')
                        }
                        var wxData = {
                            //执行分享
                            title: state.memberInfo.team_name, // 分享标题
                            desc: '这里有个好厂在招人，快来看看吧。', // 分享描述
                            link, // 分享链接
                            imgUrl: config + 'com_img.png', // 分享图标
                            type: 'link', // 分享类型,music、video或link，不填默认为link
                            success: function () {
                            },
                            cancel: function () {
                             }
                        }
                        wx.updateAppMessageShareData(wxData) // 微信好友
                        wx.updateTimelineShareData(wxData) // 朋友圈
                        wx.onMenuShareTimeline(wxData) // 朋友圈（旧）
                        wx.onMenuShareAppMessage(wxData) // 微信好友（旧）
                        wx.onMenuShareQQ(wxData) // QQ
                        wx.onMenuShareWeibo(wxData) // 腾讯微博
                        wx.onMenuShareQZone(wxData) // QQ空间
                    })

                }
            });
        },
        // setStorage(state, key,value){
        //     sessionStorage.setItem(key,value)
        // },
        // getStorage(state, key){
        //     sessionStorage.getItem(key)
        // },
        //es6语法，等同edit:funcion(){...}
        update(state, item) {
            for (let key in item) {
                state.idcardList[key] = item[key];
            }
        },
        update_bankList(state, item) {
            for (let key in item) {
                state.bankList[key] = item[key];
            }
        },
        applyList_add(state, item) {
            for (let key in item) {
                state.applyList[key] = item[key];
            }
        },
        idcardURL_add(state, data) {
            state.idcardURL[data.index] = data.item;
        },
    },
});

export default store;
