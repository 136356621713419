import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
const routers = [
    {
        path: '/',
        redirect: '/recruitmentList',
    },
    {
        path: '/networkError',
        name: 'networkError',
        component: resolve => require(['@/view/order/networkError'], resolve),
        meta: {
            title: '网络异常'
        }
    },
    {
        path: '/404',
        name: '404',
        component: resolve => require(['@/view/order/404'], resolve),
        meta: {
            title: '404'
        }
    },
    {
        path: '/recruitmentDetails',
        name: 'RecruitmentDetails',
        component: resolve => require(['@/view/job/details'], resolve),
        meta: {
            title: '详情'
        }
    },
    {
        path: '/recruitmentDetailsInfo',
        name: 'RecruitmentDetails',
        component: resolve => require(['@/view/job/details'], resolve),
        meta: {
            title: '详情'
        }
    },
    {
        path: '/recruitmentScreen',
        name: 'RecruitmentScreen',
        component: resolve => require(['@/view/job/screen'], resolve),
        meta: {
            title: '筛选'
        }
    },
    {
        path: '/subSuccess',
        name: 'SubSuccess',
        component: resolve => require(['@/view/home/SubSuccess'], resolve),
        meta: {
            title: '提交成功',
            no_need_tel: true
        }
    },
    {
        path: '/jobImages',
        name: 'JobImages',
        component: resolve => require(['@/view/job/images'], resolve),
        meta: {
            title: '分享招工图'
        }
    },
    {
        path: '/lookWages',
        name: 'LookWages',
        component: resolve => require(['@/view/home/lookWages'], resolve),
        meta: {
            title: '查看工资'
        }
    },
    {
        path: '/lookWagesDetails',
        name: 'LookWagesDetails',
        component: resolve => require(['@/view/home/lookWagesDetails'], resolve),
        meta: {
            title: '查看工资'
        }
    },
    {
        path: '/recommendList',
        name: 'RecommendList',
        component: resolve => require(['@/view/home/recommendList'], resolve),
        meta: {
            title: '推荐记录',
            keepAlive: true, // 此组件需要被缓存
        }
    },
    {
        path: '/storeAddressList',
        name: 'StoreAddressList',
        component: resolve => require(['@/view/order/StoreAddressList'], resolve),
        meta: {
            title: '门店地址',
            no_need_tel: true
        }
    },
    {
        path: '/recruitmentList',
        name: 'RecruitmentList',
        component: resolve => require(['@/view/job/index'], resolve),
        meta: {
            title: '找工作',
            keepAlive: true, // 此组件需要被缓存
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: resolve => require(['@/view/home/index'], resolve),
        meta: {
            title: '我的',
            keepAlive: true, // 此组件需要被缓存
        }
    },
    {
        path: '/myLove',
        name: 'MyLove',
        component: resolve => require(['@/view/home/love'], resolve),
        meta: {
            title: '我的收藏'
        }
    },
    {
        path: '/applyList',
        name: 'ApplyList',
        component: resolve => require(['@/view/home/apply'], resolve),
        meta: {
            title: '报名记录',
        }
    },{
        path: '/interviewjilu',
        name: 'interviewjilu',
        component: resolve => require(['@/view/home/interviewjilu'], resolve),
        meta: {
            title: '待面试记录',
        }
    },{
        path: '/myInfo',
        name: 'MyInfo',
        component: resolve => require(['@/view/home/info'], resolve),
        meta: {
            title: '我的信息',
            keepAlive: true, // 此组件需要被缓存
        }
    },
    {
        path: '/phoneNum',
        name: 'PhoneNum',
        component: resolve => require(['@/view/home/phoneNum'], resolve),
        meta: {
            title: '手机号码'
        }
    },
    {
        path: '/walletlist',
        name: 'walletlist',
        component: resolve => require(['@/view/home/walletlist'], resolve),
        meta: {
            title: '我的钱包'
        }
    },{
        path: '/monetdetail',
        name: 'monetdetail',
        component: resolve => require(['@/view/home/monetdetail'], resolve),
        meta: {
            title: '详情'
        }
    },
    {
        path: '/editnamecard',
        name: 'editnamecard',
        component: resolve => require(['@/view/home/editnamecard'], resolve),
        meta: {
            title: '基本信息'
        }
    },
    {
        path: '/imgkan',
        name: 'imgkan',
        component: resolve => require(['@/view/home/imgkan'], resolve),
        meta: {
            title: '合同附件'
        }
    },
    {
        path: '/bankedit',
        name: 'bankedit',
        component: resolve => require(['@/view/home/bankedit'], resolve),
        meta: {
            title: '银行卡信息'
        }
    },
    {
        path: '/myNews',
        name: 'MyNews',
        component: resolve => require(['@/view/home/news'], resolve),
        meta: {
            title: '我的消息'
        }
    },
    {
        path: '/addWechatCode',
        name: 'AddWechatCode',
        component: resolve => require(['@/view/home/wx'], resolve),
        meta: {
            title: '我的招聘经纪人'
        }
    },
    {
        path: '/borrowingList',
        name: 'BorrowingList',
        component: resolve => require(['@/view/home/borrowingList'], resolve),
        meta: {
            title: '借支',
            keepAlive: true, // 此组件需要被缓存
        }
    },
    {
        path: '/borrowingDetails',
        name: 'BorrowingDetails',
        component: resolve => require(['@/view/home/borrowingDetails'], resolve),
        meta: {
            title: '借支详情'
        }
    },
    {
        path: '/dimissionNext',
        name: 'dimissionNext',
        component: resolve => require(['@/view/home/dimissionNext'], resolve),
        meta: {
            title: '申请离职'
        }
    },
    {
        path: '/dimission',
        name: 'Dimission',
        component: resolve => require(['@/view/home/dimission'], resolve),
        meta: {
            title: '申请离职'
        }
    },
    {
        path: '/entryInfo',
        name: 'EntryInfo',
        component: resolve => require(['@/view/home/entryInfo'], resolve),
        meta: {
            title: '在职信息'
        }
    },
    {
        path: '/dimissionSuccess',
        name: 'DimissionSuccess',
        component: resolve => require(['@/view/home/dimissionSuccess'], resolve),
        meta: {
            title: '提交成功'
        }
    },
    {
        path: '/applyMoney',
        name: 'ApplyMoney',
        component: resolve => require(['@/view/home/ApplyMoney'], resolve),
        meta: {
            title: '申请借支'
        }
    },
    {
        path: '/writeDetails',
        name: 'WriteDetails',
        component: resolve => require(['@/view/home/writeDetails'], resolve),
        meta: {
            title: '冲销记录'
        }
    },
    {
        path: '/entry',
        name: 'Entry',
        component: resolve => require(['@/view/order/Entry'], resolve),
        meta: {
            title: '入职申请'
        }
    },
    {
        path: '/verify',
        name: 'Verify',
        component: resolve => require(['@/view/Verify'], resolve),
        meta: {
            title: '邀请认证'
        }
    },
    {
        path: '/successLogin',
        name: 'SuccessLogin',
        component: resolve => require(['@/view/SuccessLogin'], resolve),
        meta: {
            title: '验证成功'
        }
    },
    {
        path: '/wXBindCode',
        name: 'WXBindCode',
        component: resolve => require(['@/view/WXBindCode'], resolve),
        meta: {
            title: '智工邦'
        }
    },
    {
        path: '/WXBind',
        name: 'WXBind',
        component: resolve => require(['@/view/WXBind'], resolve),
        meta: {
            title: '绑定微信',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: resolve => require(['@/view/login/login'], resolve),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/empty',
        name: 'Empty',
        component: resolve => require(['@/view/login/empty'], resolve),
        meta: {
            title: '登录'
        }
    }
]
const router = new VueRouter({
    routes: routers
})

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) { 
      from.name ? next({
        name: from.name
      }) : next('/404'); 
    } else {
      next(); //如果匹配到正确跳转
    }
  });
export default router