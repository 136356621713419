import Vue from 'vue'
import App from './App.vue'
import router from './route'
import 'amfe-flexible'
import store  from  './store'
import axios from 'axios'
import './iconfont/iconfont.css'
import './assets/css/index.css'
import Request from '@/utils/request.js'
import { Button,Icon,DatetimePicker,Popup,Field,NavBar,Checkbox,Rate,Uploader,Picker,Swipe, SwipeItem ,Notify,List,PullRefresh ,Loading,Tabbar, TabbarItem,Overlay,Toast  } from 'vant';
Vue.use(Button).use(Icon).use(DatetimePicker).use(Popup).use(Field).use(NavBar).use(Checkbox).use(Rate).use(Uploader).use(Picker).use(Swipe)
.use(SwipeItem).use(Notify).use(List).use(PullRefresh).use(Loading).use(Tabbar).use(TabbarItem).use(Overlay).use(Toast)
Vue.config.productionTip = false

Vue.prototype.axios = axios;
Vue.prototype.Request = Request;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }
  next()
})
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
