import axios from "axios";
import {
    Toast
} from "vant";
import router from "../route";

axios.defaults.withCredentials = false; //false  不跨域  true 跨域
/**
 * 请求
 * @param {String} url url
 * @param {String} method 请求方式
 * @param {Object} params 传值
 * @param {Boolean} needToken 是否需要登录态
 * @param {Boolean} needLoading 是否需要loading
 */
export default function Request(
    url,
    method = "get",
    params,
    needToken = true,
    needLoading = true
) {

    // 请求拦截
    axios.interceptors.request.use(function(config) {
        return config;
    }, function(error) {
        return Promise.reject(error);
    });
    //响应拦截
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        Toast.fail("网络链接错误，请稍后再试");
        return Promise.resolve(error.response)
    })
    if (needLoading) {
        Toast.loading("加载中");
    }
    // localStorage.setItem('token','eyJBbGciOiAiTUQ1IiwgIlR5cCI6ICJKV1QifQ==.eyJSb2xlIjogImpvYnNlZWtlciIsICJTZXJ2aWNlIjogIldvcmtlckFnZW50VGVhbSIsICJUaW1lc3RhbXAiOiAiMTYzMDI4NzcxOCIsICJUb2tlbiI6ICI4NEEwMDgwMDQ4NzQ3MDM4RjNGMDk3OTc5MDg1OTA1QiIsICJVdWlkIjogIjQyMTE4In0=.25E46B035E8F10C498E1D744835EF806')
    var token = localStorage.getItem("token");
    var hrefUrl = location.href.split("#/")[1];
    return new Promise((resolve, reject) => {
        var need_token = true; // 访问接口需要token
        if (!needToken) {
            // 不需要token
            need_token = false;
        }
        // 没有token且需要token的请求，需要先登录
        if (!token && need_token) {
            if (
                hrefUrl.indexOf("login") == -1 &&
                hrefUrl.indexOf("empty") == -1
            ) {
                localStorage.setItem("is_token", hrefUrl);
            }
            router.push({
                path: "/login",
            });
        } else {
            //get请求
            if (method === "get" || url == "client/mine/info" || method == 'put') {
                axios.defaults.headers["Content-Type"] =
                    "application/json;charset=UTF-8";
            } else {
                axios.defaults.headers["Content-Type"] =
                    "application/x-www-form-urlencoded";
                if (params) {
                    let par = new FormData();
                    let key = Object.keys(params);
                    for (var i = 0; i < key.length; i++) {
                        par.append(key[i], params[key[i]]);
                    }
                    params = par;
                }
            }

            var baseURL = process.env.VUE_APP_BASE_API; // api请求
            if (url == "account/verify" || url == '/WATS/send_sms') {
                if (url == '/WATS/send_sms') {
                    url = 'send_sms'
                }
                // 加入团队的时候，请求的接口跟H5的不一样
                baseURL = process.env.VUE_APP_BASE_VERIFY;
            }
            // baseURL = 'https://wat-api.xiaocaojob.cn/v2/WorkerAgentTeam/'
            // baseURL = 'http://192.168.0.97:18834/WorkerAgentTeam/'; // api请求
            axios({
                    baseURL,
                    method,
                    url,
                    headers: {
                        Jwt: token && needToken ? token : "",
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Cache-Control": "no-cache",
                        // 'Content-Type': 'application/json',
                    },
                    data: params,
                    params,
                    timeout: 15000,
                })
                .then((result) => {
                    if (needLoading) {
                        Toast.clear();
                    }
                    // if (!result) {
                    //     Toast('网络链接错误，请稍后再试');
                    //     return
                    // }
                    if ( result.status == 200 && 
                        result.headers &&
                        (result.headers.Jwt || result.headers.jwt)
                    ) {
                        
                        localStorage.setItem(
                            "token",
                            result.headers.Jwt || result.headers.jwt
                        );
                    }
                    if (result.data.status == -403000) {
                        localStorage.removeItem("token");
                        if (
                            hrefUrl.indexOf("login") == -1 &&
                            hrefUrl.indexOf("empty") == -1
                        ) {
                            localStorage.setItem("is_token", hrefUrl);
                        }
                        router.push({
                            path: "/login",
                        });
                        return;
                    }
                    if (
                        result.data.status != -400000 &&
                        result.data.status != -10 &&
                        result.data.status != -4 &&
                        result.data.status < 0 &&
                        url != 'client/jobseeker/info'
                    ) {
                        Toast(result.data.msg || '网络链接错误，请稍后再试');
                        // return;
                    }

                    resolve(result.data);
                })
                .catch((err) => {
                    if (needLoading) {
                        Toast.clear();
                    }
                    Toast.fail("网络链接错误，请稍后再试");
                    reject(err);
                });
        }
    });
}
